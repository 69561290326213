<template>
  <v-dialog v-model="show" persistent :retain-focus="false" max-width="1000">
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5"><strong> Edit Hospital</strong></span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.hospital_name" label="Hospital Name*" readonly required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.address" label="Address*" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.phone_number" label="Phone No*" required counter="10"
                @keypress="onlyNumber"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete v-model="item.hospital_type" label="Type" :items="Type" @change="sss()"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="item.hospital_type == 'HCRP Hospital'">
              <v-text-field v-model="item.contact_name" label="Contact Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.hospital_type == 'Transplantation Hospital'">
              <v-autocomplete v-model="item.hospital_category" label="Hospital Category" :items="Category"
                @change="ss()"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="item.hospital_type == 'Transplantation Hospital'">
              <v-text-field v-model="item.surgeon_name" label="Surgeon Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.hospital_category === 'CDS'">
              <v-autocomplete v-model="item.state" label="States" required :items="state2" item-text="state_name"
                @change="dateItem()"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" v-if="item.hospital_category != 'CDS'">
              <v-autocomplete v-model="item.district" label="District" required @change="dataItem1()"
                :items="districtList" item-text="district_title"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" v-if="item.hospital_category == 'CDS'">
              <v-autocomplete v-model="item.district" label="District 2" required @change="dataItem2()"
                :items="districtList2" item-text="district_title"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete v-model="item.city" label="City" required :items="roleList"
                item-text="name"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.email" label="Email*" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.area" label="Area" required></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="item.contact_name" label="Contact Name" required></v-text-field>
              </v-col> -->
            <v-col cols="12" sm="6" md="4">
              <v-datetime-picker v-model="HOTAvalidity" time-format="HH:mm:ss" label="HOTA Validity"></v-datetime-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-file-input v-model="file" chips accept="image/*" label="Certificate" @change="onFileChange" />
            </v-col>
            <v-col v-if="!fileObj && item.upload_certificate" cols="2">
              <img v-img style="width:120%" :src="!fileObj ? `https://api.ebsr.in/${item.upload_certificate}` : ''" />
            </v-col>

            <v-col v-if="!!fileObj" cols="2">
              <img v-img style="width: 120%;" :src="fileObj ? fileObj : ''" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="show = false"> Cancel </v-btn>
        <v-btn color="primary" @click="editHospital()" :disabled="!formIsValid"> Save </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import serviceHospital from '@/service/EyeDonationCenter.service'
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
import moment from 'moment'

export default {
  data() {
    return {
      fileObj: '',
      citysearch: [],
      file: null,
      image: null,
      Type: ['HCRP Hospital', 'Transplantation Hospital'],
      Category: ['Government Hospital', 'Private Hospital', 'CDS'],
      state2: [{ state_name: "", state_id: '' }],
      roleList: [{ city_id: "", name: "" }],
      districtList: [{ districtid: "", district_title: "", }],
      districtList2: [{ districtid: '', district_title: "" }],
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
    }
  },
  computed: {
    formIsValid() {
      return (
        this.item.hospital_name &&
        this.item.email.match(validRegex) &&
        this.item.phone_number &&
        this.item.address &&
        this.item.area

      )
    },
    HOTAvalidity: {
      get() {
        return this.item.HOTA_validity ? moment(this.item.HOTA_validity).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.item, 'HOTA_validity', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
          this.$emit('getHospitalList')
          setTimeout(() => {
            location.reload()
          }, 100)
        }
      },
    },
  },


  watch: {
    options: {
      handler() {
        this.GetDistrictList()
        this.GetCity()
        this.GetstatetList()
        this.GetDistrictList1()
      },
      deep: true,
    },
  },

  async mounted() {
    this.GetDistrictList()
    this.GetCity()
    this.GetstatetList()
    this.GetDistrictList1()

  },

  props: ['visible', 'item'],


  methods: {
    sss() {
      if (this.item.hospital_type === 'HCRP Hospital') {
        this.item.hospital_category = ''
      }
    },
    ss() {
      if (this.item.hospital_category != 'CDS') {
        this.item.state = ''
      }
    },
    dateItem() {
      const stateId = this.state1.find(o => o.state_name === this.item.state);
      const valuee = stateId.state_id
      // console.log("state id", valuee)
      const CityId = this.districtList1.filter((e) => e.state_id == valuee)
      // console.log("city name",CityId)
      this.districtList2 = CityId
    },
    dataItem2() {
      console.log("this.districtList2", this.districtList2)
      const district = this.districtList1.find(o => o.district_title === this.item.district)
      const valuee = district.districtid
      // console.log("skeeeee", district)
      const cityIDD = this.citysearch.filter((e) => e.district_id == valuee)
      // console.log("city", cityIDD)
      this.roleList = cityIDD

    },

    dataItem1() {
      const districtid = this.districtList.find(e => e.district_title === this.item.district)
      const valuee = districtid.districtid
      // console.log("disdisdf",valuee)
      const cityIDD = this.citysearch.filter((e) => e.district_id == valuee)
      //  console.log("hellooo",cityIDD)
      this.roleList = cityIDD
    },

    onFileChange() {
      this.fileObj = URL.createObjectURL(this.file)
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = e => {
        this.item.upload_certificate = e.target.result
      }
    },

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault();
      }
    },

    async GetDistrictList1() {
      const service = new serviceHospital()
      let response = await service.DistrictList11()
      if (response) {
        this.districtList = response.data

        // console.log("districtis",this.districtList)

      } else {
        this.districtList = []
      }
    },

    async GetDistrictList() {
      const service = new serviceHospital()
      let response = await service.DistrictList()
      if (response) {
        this.districtList1 = response.data
        this.districtList2 = response.data
      } else {
        this.districtList1 = []
      }
    },

    async GetCity() {
      const service = new serviceHospital()
      let response = await service.CityList()
      if (response) {
        this.roleList = response.data
        this.citysearch = response.data

      } else {
        this.roleList = []
      }
    },
    async GetstatetList() {
      const service = new serviceHospital()
      let response = await service.stateList()
      if (response) {
        this.state1 = response.data
        const Statedata = this.state1.filter((d) => d.state_name != 'Rajasthan')
        this.state2 = Statedata
      } else {
        this.state1 = []
      }
    },

    async editHospital() {
      if (this.item.phone_number.length < 10) {
        return false,
          this.snackbarText = "Phone number should be 10 character.",
          this.snackbar = true
      }
      try {
        const data = {
          id: this.item.id,
          hospital_name: this.item.hospital_name,
          address: this.item.address,
          phone_number: this.item.phone_number,
          city_id: this.item.city,
          district_id: this.item.district,
          email: this.item.email,
          area: this.item.area,
          contact_name: this.item.contact_name,
          hospital_type: this.item.hospital_type,
          surgeon_name: this.item.surgeon_name,
          HOTA_validity: this.item.HOTA_validity,
          upload_certificate: this.item.upload_certificate,
          hospital_category: this.item.hospital_category,
          state: this.item.state
        }
        const service = new serviceHospital()
        const response = await service.updateHospital(data)
        if (response.status == 200) {
          this.snackbarText = response.message,
            this.snackbar = true
          setTimeout(() => {
            this.show = false
          }, 1000)
        } else {
          this.snackbarText = response.message,
            this.snackbar = true
        }
      } catch (e) {
        console.log(e)
      }
    }

  }
}

</script>