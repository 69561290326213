var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/users/`;
var localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
var token = localData.token
export default class donor {
    async hospitalList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}view/hospital`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async CityList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}cities`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async OneCityList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}cities_by_state/29`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async stateList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}state`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async DistrictList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}district`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async DistrictList11() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}district_by_state/29`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async updateHospital(data) {
        try {
            const response = await axios.post(`${baseUrl}edit_hospital`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };

}